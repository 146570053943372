import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="coming-soon-header">
        <div className="container text-center">
          <h1 className="display-4">CoinSend.io</h1>
          <p className="lead">Empowering Cross-border Transactions with USDT and Bitcoin</p>
          <h3 className="coming-soon-text">Coming Soon</h3>
          <p>Our platform will be available soon! Stay tuned for an easy way to pay bills, make purchases, send money, and more with cryptocurrency.</p>
          <form className="mt-4">
            <input
              type="email"
              className="form-control"
              placeholder="Enter your email for updates"
            />
            <button type="submit" className="btn btn-primary mt-2">
              Notify Me
            </button>
          </form>
        </div>
      </header>
    </div>
  );
}

export default App;
